.footer_logo{
height: 100px;
float: left;
padding-bottom: 2vh;
}
.footer_head_main{
    font-size: 2.2rem !important;
    font-weight: 700 !important;
    color: white;
    padding-bottom: 20px;
    text-align: left;
}
.hvr-underline-from-left-white {
    /*color: #0c253f;*/
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-left-white:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #fff;
    height: 1px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-left-white:hover:before, .hvr-underline-from-left-white:focus:before, .hvr-underline-from-left-white:active:before {
    right: 0;
  }
  
  
  /* .footer_sub {
      
  } */
  .footer_sub{
    /* color: var(--color1) !important; */
    color: #fff;
    /* text-decoration: underline; */
text-align: left;
width: 69%;
    text-decoration: none;
    font-weight: 500;
   padding-bottom: 10px;
    font-size: 1.3rem !important;
    cursor: pointer;
}
.footer_sub2{
    /* color: var(--color1) !important; */
    color: #fff;
    /* text-decoration: underline; */
text-align: left;
width: 100%;
    text-decoration: none;
    font-weight: 500;
   padding-bottom: 10px;
    font-size: 1.3rem !important;
    cursor: pointer;
}

.footer_separator {
    height: 2px;
    background-color:white;
    width: 95%;
  }
  .footer_items{

  }
  .footer_link_title {
    font-size: 1.4rem;
    font-weight: 400;
    color: white;
    margin-bottom: 2px;
  }
  .socials {
    display: flex;
    gap: 15px;
    font-size: 2.4rem;
    margin: 0px 0px;
    padding: 5px 1vw;
    color: white;
    justify-content: flex-end;
    cursor: pointer;
  }
  .footer_cpryt_txt{
    font-weight: 400 !important;
    color: white;
    font-size: 1.5rem !important;
    padding-right: 2vw;
  }
  .footer_sub3{
    color: #fff;
    /* text-decoration: underline; */
text-align: left;
width: 100%;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.4rem !important;
    cursor: pointer;
    padding-bottom: 5px !important;
    /* text-decoration: underline; */
    border-bottom: 2px solid white;
  }

  @media (max-width:500px) {
    .socials{
      justify-content: center;
      padding-top: 2vh;
    }
    .footer_cpryt_txt{
      font-weight: 400 !important;
      color: white;
      font-size: 1.1rem !important;
      padding-right: 1vw;
      width: 69%;
    }
    .footer_sub3{
      font-size: 1.1rem !important;
      display: block;
    }
    
  }
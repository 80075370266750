.contact-left-pannel{
    background: var(--color1) !important;
    padding: 20vh 2vw 5vh;
    align-content: center;
    text-align: left;
    margin-bottom: 2px;
}





/*  */


  .career_form_container {
    width: 60%;
    margin-top: 100px;
    padding: 30px 20px;
    text-align: left;
    /* background-color: red; */
    border: 0.5px solid rgb(210, 210, 210);
    border-radius: 5px;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.1);
  }
  
  .career_form_container > h1 {
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .career_form_container_inputbox {
    margin: 18px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .career_form_container_inputbox > label {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .career_form_container_inputbox > input,
  textarea {
    padding: 15px 10px;
    border: 1px solid rgb(207, 207, 207);
    font-size: 1.1rem;
    outline: none;
    border-radius: 5px;
  }
  
  .career_button {
    padding: 5px 10px;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    background: var(--color1) !important;
    border-radius: 5px;
    border: 1px solid transparent;
  }
  .career_button:hover {
    border: 1px solid  var(--color1);
    background-color: transparent !important ;
    color:  var(--color1);
  }
  
  /* Medaia Query for Mobile Design ///////////////////////////////////////////////////////////////// */
  @media all and (width <= 480px) {
    .career_container {
      padding: 30px 20px;
      display: block;
      flex-direction: none;
      width: 100%;
    }
    .career-data-container {
      width: 100%;
      margin-bottom: 20px;
    }
    .career_form_container {
      width: 100%;
      padding: 30px 15px;
    }
    .career_button {
      width: 100%;
    }
    .career_para {
      font-size: 1.1rem;
    }
    .career_head2 {
      font-size: 1.5rem;
    }
  }
  /* Media Query for Tablet Design */
  @media all and (width > 480px) and (width <= 768px) {
    .career_container {
      padding: 30px 20px;
      display: block;
      flex-direction: none;
      width: 100%;
    }
    .career-data-container {
      width: 100%;
      margin-bottom: 20px;
    }
    .career_form_container {
      width: 100%;
      padding: 30px 15px;
    }
    .career_button {
      width: 100%;
    }
    .career_para {
      font-size: 1.1rem;
    }
    .career_head2 {
      font-size: 1.5rem;
    }
  }
  /* Media Queries for Small Laptops and Desktops  */
  @media all and (width > 786px) and (width < 1124px) {
    .career_container {
      padding: 30px 20px;
      display: flex;
      flex-direction: none;
      width: 100%;
    }
    .career-data-container {
      width: 40%;
      margin-bottom: 20px;
    }
    .career_container {
      padding: 30px 20px;
      display: flex;
      flex-direction: none;
      width: 100%;
    }
  
    .career_form_container {
      width: 60%;
      margin: 10px 0px;
      height: auto;
    }
    .career_form_container > h1 {
      font-size: 1.2rem;
    }
    .career_form_container_inputbox > label {
      font-size: 0.8rem;
      font-weight: 500;
    }
    .career_form_container_inputbox > input,
    textarea {
      padding: 10px 10px;
      border: 1px solid rgb(207, 207, 207);
      font-size: 0.8rem;
      outline: none;
      border-radius: 5px;
    }
    .career_para {
      font-size: 0.8rem;
    }
    .career_button {
      font-size: 0.8rem;
    }
    .career_head2 {
      font-size: 1.3rem;
    }
  }
  
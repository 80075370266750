.home_top_main_comp{
    margin-top:170px
}

.home_top_bg{
    background-color: var(--color1);
    background-image: url('../../../images/background_home.webp');
background-size: cover;
padding-top:150px;
background-repeat: no-repeat;
position: absolute;
height: 105vh;
}
.home_top_bg2{

    margin-top: 85vh;
    z-index: 1;
    padding: 5vh;
}

.home_top_txt1{
    color: var(--color2);
    font-size: 1.6rem !important;
    font-weight: 600 !important;
}
.home_top_txt2{
    color: var(--color1);
    margin-top: 5vh;
    font-size: 3.9rem !important;
    font-weight: 700 !important;
}
.home_top_txt2_new{
    color: var(--color1);
    margin-top: 5vh !important;
    font-size: 3rem !important;
    font-weight: 700 !important;
}

.home_top_txt3{
    padding: 5vh 0px;
    font-size: 1.8rem !important;
    color: darkslategrey;


}

.home_top_ani_comp{
min-height: 30vh;
width: 100%;
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25);
border-radius: 10px;}


.home_top_txt_scroll_main{
    padding: 2vh 0px !important;
    font-size: 3rem !important;
    color: var(--color2);
    font-weight: 600 !important;
}
.home_top_ppr {
    height: 140px;
    background-color: var(--color1) !important;
    border-radius: 10px !important;

}
.home_top_ppr:hover {
       box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;

}
.home_top_ppr_txt{
    height: 100%;
    align-content: center;
    color: white;
    font-weight: 700 !important;
}
.home_top_use_headM{
    color: var(--color1);
    padding: 4vh 1vw;
    font-size: 3.5rem !important;
    font-weight: 600 !important;
}
.home_top_ppr2{
    height: 265px;
    border-radius: 10px !important;
    border:2px solid var(--color1);
    width: 95%;
}


.home_top_ppr3{
    height: 395px;
    border-radius: 10px !important;
    border:2px solid var(--color1);
    width: 96%;
}

.home_top_ppr3_head{
    color: var(--color1);
    padding: 2vh 0px !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
}
.home_top_ppr3_desc{
    padding: 1vh !important;
    padding-bottom: 20px !important;
    font-size: 1.2rem !important;
    color: darkslategrey;
    text-align: justify;
}

.home_top_ppr3:hover{
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;
  
}





    .home_top_ppr3_head{
        color: var(--color1);
        padding: 2vh 0px !important;
        font-size: 1.5rem !important;
        font-weight: 600 !important;
    }
    .home_top_ppr3_desc{
        padding: 1vh !important;
        padding-bottom: 20px !important;
        font-size: 1.2rem !important;
        color: darkslategrey;
        text-align: justify;
    }
    
   
    
.home_top_ppr2_head{
    color: var(--color2);
    padding: 2vh 0px !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
}
.home_top_ppr2_desc{
    padding: 1vh !important;
    padding-bottom: 20px !important;
    font-size: 1.2rem !important;
    color: darkslategrey;
    text-align: justify;
}

.home_top_ppr2:hover{
    background-color: var(--color1) !important;
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;
  
}


.home_top_ppr2:hover .home_top_ppr2_head{
color: white !important;  
}
.home_top_ppr2:hover .home_top_ppr2_desc{
    color: white !important;  
    }
.home_top_ppr_gif{
height: 140px !important;
    }

@media (max-width:500px) {


    .home_top_bg{
        padding-top:130px;
background-repeat: no-repeat;
height: 105vh;
    }
    .home_top_txt1{
        font-size: 1.4rem !important;
        text-align: left;

    }
    .home_top_txt2{
       
        font-size:1.7rem !important;
        text-align: left;
        padding-top: 2vh;
      
    }
    .home_top_txt2_new{
       
        font-size:1.7rem !important;
        text-align: left;
        padding-top: 2vh;
      
    }
    .home_top_txt3{
        padding: 2vh 0px;
        font-size: 1.1rem !important;
        color: darkslategrey;
        text-align: left;
    
    }
    .home_top_bg2{

        margin-top: 80vh;
    }
    .home_top_txt_scroll_main{
        padding: 2vh 0px !important;
    font-size: 2rem !important;
    text-align: left;
    
    }
    .home_top_use_headM{
        padding: 4vh 1vw;
        font-size: 2.5rem !important;
        text-align: left;
    }
    .home_top_ppr2{
        margin-bottom: 2vh;
    }
    .home_top_ppr3{
        margin-bottom: 2vh;
    }
    }
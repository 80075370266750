.mainHead_opn{
    text-align: left;
    font-size: 3rem !important;
    padding: 2vh 0px;
    color: var(--color1);
}
.maindesc_opn{
    text-align: left;
    padding: 1vh 0px;
    font-size: 1.4rem !important;
    /* font-weight: 500 !important; */
}
.subdesc_opn{
    text-align: left;
    padding: 1vh 0px;
    font-size: 1.1rem !important;
}
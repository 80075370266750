.pp-container > h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 10px 0px;
}

.pp-container > p {
    font-size: .9rem;
    font-weight: 400;
    padding: 5px 0px;
    color: rgb(58, 58, 58);
}

.pp-container > ul> li{
    
    font-size: .9rem;
    font-weight: 400;
    padding: 3px 0px;
    color: rgb(58, 58, 58);
}

span{
    font-weight: 700;
    font-size: 1rem;
}

.pp-container a{
    color: var(--btn-purple);
}
.Navbar_Items {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
   position: fixed; 
    top: 0;
    z-index: 101;
    background: white;
    border-bottom-style: solid;
    border-bottom-color: var(--color2);
    border-bottom-width: 3px;

}
.Navbar_Link1{
    
    color: var(--color1) !important;

    text-decoration: none;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.3rem !important;
    cursor: pointer;
}
.Navbar_Link2 {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color:var(--color1);

    font-weight: 700;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.3rem !important;
    cursor: pointer;
    /* border-bottom-style: solid;
    padding-bottom: 5px; */

}




.Grid_MC{
    height: 100%;
    align-content: center;
    justify-content:end;
    padding-right: 60px;
}
.Navbar_Items1 {
    width: 100%;
   padding: 15px 0px;
    display: flex;
    justify-content: space-between;
 
    /* color: black; */

    z-index: 101;
}

/* .Navbar_Link2N {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color:  var(--color1);
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-style: solid;
    padding-bottom: 5px;

} */
.icon_gridM{text-align: start;
padding-left: 20px;
align-self: center;
cursor: pointer;}
.icons_nav{
    padding-left: 5px;
    padding-right: 5px;
    font-size: 21px !important;
}

.icon_ham{
    color:  var(--color1);
    font-size: 26px;
    padding-left: 20px;
    margin-top: 2px;
    /* font-weight: 500 !important; */
    align-self: center;
    padding-right: 3vw;
}
.icon_gridM1{
   display: none;
}
.nav_btn{
    color: white !important;
    font-weight: 600 !important;
    background-color: var(--color2) !important;
    border-radius: 5px !important;
    padding: 10px !important;
    
}


.nav_cmp_main_p{
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 70px;

    left: 5%;
    display: none;
}
.nav_sub_cmp_p{
    height: auto;
    width: 80%;
    background: rgb(238, 238, 238);
    position: relative;
    /* top: 113px; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.product:hover .nav_cmp_main_p{

   display: block;

}



.nav_cmp_main_s{
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 70px;

    left: 5%;
    display: none;
}
.nav_sub_cmp_s{
    height: auto;
    width: 80%;
    background: white;
    position: relative;
    /* top: 113px; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

}

.solutions:hover .nav_cmp_main_s{

   display: block;

}

.nav_cmp_main_head{
    color: grey !important;
    font-weight: 700 !important;
    font-size: 1.4rem !important;
    padding: 10px 0px;
    text-transform: uppercase;
}

.nav_cmp_sub_head{
    font-weight: 700 !important;
    font-size: 1.3rem !important;
    padding: 10px 0px;
    
}
.gird_nav{
    padding-right: 4rem !important;
}
.header_mob{
    
}
.header_mob2{
    display: none !important;
}



.hvr-underline-from-left-purple {
    /*color: #0c253f;*/
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-left-purple:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: var(--color2);
    height: 2px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-left-purple:hover:before, .hvr-underline-from-left-white:focus:before, .hvr-underline-from-left-white:active:before {
    right: 0;
  }


@media (max-width:500px) {


    .Grid_MC{
       display: none;
    }
    .icon_gridM1{
        display: block;

        align-self: center;
        text-align-last: end;
    }
    .Navbar_Items1{
        display: none;
    }
    .icon_gridM {
        padding-left: 5px;

    }
    .header_mob{
        display: none !important;
    }
    .header_mob2{
        display: block !important;
    }
    .gird_nav{
        padding-right: 0 !important;
    }
    .nav_cmp_main_head{
        color: grey !important;
        font-weight: 700 !important;
        font-size: 1rem !important;
        padding: 10px 0px;
        /* padding-left: 10.5px; */
        text-transform: uppercase;
    }
    .nav_cmp_sub_head{
        font-weight: 700 !important;
        font-size: .9rem !important;
        padding: 10px 0px;
    }
}










.home_below_main_comp{
    padding-top:5vh;
}
.home_below_mis_head{
    color: white !important;
    font-size: 1.7rem !important;
    text-align: left;
    padding: 2vh 0px;
}
.home_below_mis_sub_head{
    font-size: 3.8rem !important;
    font-weight: 600 !important;
    padding-bottom: 2vh;
    color: white;
    text-align: left;
}
.home_below_mis_sub_head_desc{
    font-size: 2rem !important;
    padding-bottom: 2vh;
    color: white;
    text-align: left;
}

.home_below_mis_num_comp{
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin: 1vh 0px;
    width: 70%;
}
.home_below_mis_num{
    font-size: 3rem !important;
    color: var(--color1);
    font-weight: 600 !important;
}


.home_below_mis_num_head{
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    color: var(--color2);
}

.home_below_mis_img{
padding-right: 10px;
border-top-left-radius: 200px;
width: 100%;
}



.home_below_ready_btn1{
   background-color:  var(--color2) !important;
    color: white !important;
    padding: 1.5vh 4vw !important;
    border-radius: 14px !important;
    font-size: 1.1rem !important;
    border:2px solid  var(--color2) !important;
    transition-duration: 0.2s !important;
    transition-timing-function: ease-in-out !important;
}

.home_below_ready_btn1:hover{
    transform: scale3d(1.05, 1.05, 1.05);
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;

}

.home_below_ready_btn2{
    border:2px solid  var(--color2) !important;
    color:var(--color2) !important;
    padding: 1.5vh 4vw !important;
    border-radius: 14px !important;
    font-size: 1.1rem !important;

}
.home_below_ready_btn2:hover{
    transform: scale3d(1.05, 1.05, 1.05);
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;

}


@media (max-width:500px){
    .home_below_mis_sub_head{
        font-size: 2.8rem !important;
      
    }
    .home_below_mis_sub_head_desc{
        font-size: 1.5rem !important;
     
    }
}



.about_img{
    min-height:50vh ;
    width: 100%;
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
object-fit: cover;
height: 100%;

}


.about_mission_head{
    font-size: 2.5rem !important;
        color: var(--color1);
        min-height: 120px;

        font-weight: 700 !important;
        text-align: left;
        padding: 5vh 2vw;
    }
    .about_mission__desc{
        font-size: 1.3rem !important;
        color: darkslategray;
        text-align: left;
        padding: 0px 2vw;
        padding-bottom: 5vh !important;
    }


    
.about_vision_head{
    font-size: 2.5rem !important;
        color:white;
        min-height: 150px;
        font-weight: 700 !important;
        text-align: left;
        padding: 5vh 2vw;
    }
    .about_vision__desc{
        font-size: 1.3rem !important;
        color: white;
        text-align: left;
        padding: 0px 2vw;
        padding-bottom: 5vh !important;

    }

    .about_records_num{
        font-size: 3rem !important;
        font-weight: 600 !important;
        color: var(--color1);
    }
    .about_records_head{
     color:   darkslategray !important;
    font-size: 1.3rem !important;
    }



    @media (max-width:500px) {
        .about_mission_head{
            font-size: 2rem !important;
        }
        .about_vision_head{
            font-size: 2rem !important;

        }
    }
.products_N_S_logo{
height: 110px;
}
.products_N_S_ppr{
min-height: 40vh;
width: 100%;
box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;

border-radius: 20px !important;
}
.products_N_S_ppr_img{
    height: 100%;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

}
.products_N_S_ppr_head{
font-size: 1.9rem !important;
    color: var(--color1);
    font-weight: 600 !important;
    text-align: left;
    padding: 5vh 2vw;
}
.products_N_S_ppr_desc{
    font-size: 1.3rem !important;
    color: var(--color1);
    text-align: left;
    padding: 0px 2vw;
}
.products_N_S_ppr_img2{
    height: 100%;
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}
.products_N_S_ppr_head2{
font-size: 1.9rem !important;
    color: white;
    font-weight: 600 !important;
    text-align: left;
    padding: 5vh 2vw;
}
.products_N_S_ppr_desc2{
    font-size: 1.3rem !important;
    color: white;
    text-align: left;
    padding: 0px 2vw;
}
.below_products_N_S_ppr{
    border-radius: 20px !important;
    height: 100%;
}
.below_products_N_S_ppr:hover{
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;

}
.below_products_N_S_ppr2{
    border-radius: 20px !important;
    height: 100%;
    background-color: var(--color1) !important;
}
.below_products_N_S_ppr2:hover{
    box-shadow:2px 6px 15px 12px rgba(238,0,0,.25) !important;

}


.below_products_N_S_ppr_img{
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.below_products_N_S_ppr_head{
    padding: 2vh 1vw;
    text-align: left;
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    color: var(--color1);
}
.below_products_N_S_ppr_desc{
    padding: 1vh 1vw;
    text-align: left;
    font-size: 1.1rem !important;
    padding-bottom: 20px;
    color: darkslategrey;
}

.below_products_N_S_ppr2_head{
    padding: 2vh 1vw;
    text-align: left;
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    color: white;
}
.products_N_S_cmp{
    background: var(--color2);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media (max-width:500px) {
    .products_N_S_logo{
        height: 53px;
    } 
    .products_N_S_ppr_img{
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;

    
    }
    .products_N_S_ppr_desc{
        padding-bottom: 10px;
    }
    .products_N_S_cmp{
     
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        padding-bottom: 10px;


    }
    .products_N_S_ppr_img2{
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;

    }
}
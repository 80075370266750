/* @import "../../MainCSS.css"; */


.MuiDrawer-paper{
    background-color: #eee !important;
}
.MuiAccordion-root{
    background-color: transparent !important;
box-shadow: none !important;
}
.typo{
    font-weight: 500 !important;
    font-size: 1.2rem !important;
    color: var(--color1) !important;
    /* border-bottom: black !important;
    border-bottom-width: 8px !important; */
    padding-left: 10px;
}
.typo1{
    font-weight: 400 !important;
    font-size: 14px !important;
    color: var(--color1) !important;
    /* border-bottom: black !important;
    border-bottom-width: 8px !important; */
    padding-left: 10px;
    padding-bottom: 20px !important;
}
.box_W{
    width: 35vw !important;
}
@media (max-width:500px) {
    .box_W{
        width: 75vw !important;
    } 
}
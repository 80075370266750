
.PP_ST_Div {
  display: flex;
  justify-content: flex-end;
  margin: 0px auto;  
  margin-right: 20px;
}
  
  .scrollTop {
    position: fixed; 
    width: 100%;
    width: 40px;
    bottom: 78px;
    align-items: center;
    color: var(--color2);
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
   /* transition: opacity 0.4s; */
    animation: Top 0.5s alternate ease infinite;
 /*   margin-left: 1300px;  */
  }

  @keyframes Top {
    from{
        transform: translateY(2px);
    }
    to{
        transform: translateY(-1px);
    }
}
  
  .scrollTop:hover{
    color:black;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

.scrolling-words-container {
  display: flex;
  align-items: center;
  font-size: 4rem;
  font-weight: 600; 
  justify-content: center;
  color: var(--color1);

}

.scrolling-words-box {
  height: 5rem;
//   margin: auto;
  overflow: hidden;
  color: var(--color1);
  
  ul {
    margin: 0 0.625rem;
    padding: 0;
    animation: scrollUp 6s infinite;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 5rem;
      list-style: none; 
    }
  }
}

// Variables
$item-count: 5;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%}, #{$i * 25%} {
      transform: translateY((-100% / $item-count) * $i);
    }
  }
}


.Test-grid{
    /* padding: 1vw 0px; */
    width: 70%;
}
.TC-grid{
    display: grid !important;
    grid-template-columns: 35% 50%;
    /* padding: 10px ; */
   
    width: 100%;
    height: 45vh;
    justify-content: center;
    align-items: center;
}
.Test-grid .nextArrow{
    color:var(--color1);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position:absolute;
    bottom: 5%;
    right: 5%;
    border: 1px solid var(--color1);
    cursor: pointer;
    z-index: 1;
}
.Test-grid .prevArrow{
    border: 1px solid var(--color1);
    color:var(--color1);
    height: 40px;
    z-index: 1;

    width: 40px;
    border-radius: 50%;
    position:absolute;
    bottom: 5%;
    right: 10%;
    cursor: pointer !important;
}

.Test-grid .prevArrow:hover {
    color: rgb(255, 255, 255);
    background-color:  var(--color1);
}

.Test-grid .nextArrow:hover{
    color: rgb(255, 255, 255);
  background-color: var(--color1);

}

.TC-grid-1{
display: flex;
justify-content: center;
align-items: center;

object-position: center;

}

.TC-grid-1 >img{
    border: 1px solid transparent;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    object-fit: cover;
}

.TC-grid-2{
text-align: left;
padding: 0px 2vw;
}

.TC-quote p{
    font-size:1.2rem ;
    margin: 20px 0px;
}

.TC-person  h1{
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color1);
}
.TC-person > h3{
    font-size: 1rem;
    font-weight: 600;
    color:var(--testimonial-gray);
}





@media  (max-width:500px){

    .Test-grid{
        /* padding: 1vw 0px; */
        width: 100%;
    }
    .TC-grid-1 >img{
        border: 1px solid transparent;
        border-radius: 50%;
        height: 110px;
        width: 110px;
        object-fit: cover;
    }
    .TC-grid{
        grid-template-columns: 29% 69%;
        /* padding: 10px ; */
   
    }
    .Test-grid .prevArrow{
              right: 19%;
    }
    


}
